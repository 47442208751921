import alterpressApi from './base';

import {
    IClientOffer,
    ICreateJobOfferMutation,
    IJobOfferUpdateData,
    ISingleOffer,
} from '../../models/job-offer.model';
import { ISurveyQuestion } from '../../models/survey.model';
import { IFilters } from '../../models/filter.model';

export const jobOfferApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['JOB_OFFER', 'CLIENT_JOB_OFFER', 'CLIENT_PROJECT'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getJobOffer: builder.query<
                ISingleOffer,
                {
                    jobOfferId: number | string | undefined | null;
                    language: string | undefined | null;
                }
            >({
                query: ({ jobOfferId, language }) =>
                    `advertisements/${jobOfferId}${language ? `?lang=${language}` : ''}`,
                providesTags: ['JOB_OFFER'],
            }),
            getClientJobOffer: builder.query<
                IClientOffer,
                {
                    jobOfferId: number | string | undefined | null;
                    clientId: number | string | undefined | null;
                    language: string | undefined | null;
                }
            >({
                query: ({ jobOfferId, clientId, language }) =>
                    `clients/${clientId}/advertisements/${jobOfferId}${
                        language ? `?lang=${language}` : ''
                    }`,
                providesTags: ['JOB_OFFER', 'CLIENT_PROJECT'],
            }),
            getJobOfferFilters: builder.query<
                { filters: IFilters; language: string } | undefined,
                void
            >({
                query: () => `advertisements/filters`,
                providesTags: ['JOB_OFFER'],
            }),
            getJobOfferQuestions: builder.query<
                ISurveyQuestion[],
                { language: string | undefined | null }
            >({
                query: ({ language }) =>
                    `clients/advertisement-questions${language ? `?lang=${language}` : ''}`,
            }),
            getJobOfferUpdateData: builder.query<
                IJobOfferUpdateData,
                number | string | undefined | null
            >({
                query: (jobOfferId) => ({
                    method: 'GET',
                    url: `/client-advertisements/${jobOfferId}/update-data`,
                }),
                providesTags: ['JOB_OFFER', 'CLIENT_PROJECT'],
            }),
            createJobOffer: builder.mutation<void, ICreateJobOfferMutation>({
                query: (data) => ({
                    url: `client-advertisements`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['CLIENT_JOB_OFFER'],
            }),
            updateJobOffer: builder.mutation({
                query: ({ mutation, jobOfferId }) => ({
                    url: `client-advertisements/${jobOfferId}`,
                    method: 'PATCH',
                    body: mutation,
                }),
                invalidatesTags: ['JOB_OFFER', 'CLIENT_JOB_OFFER'],
            }),
            deleteJobOffer: builder.mutation({
                query: (id) => ({
                    url: `client-advertisements/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['JOB_OFFER', 'CLIENT_JOB_OFFER'],
            }),
            archiveJobOffer: builder.mutation<void, number | string>({
                query: (jobOfferId) => ({
                    url: `client-advertisements/${jobOfferId}/archive`,
                    method: 'PATCH',
                }),
                invalidatesTags: ['JOB_OFFER', 'CLIENT_JOB_OFFER'],
            }),
        }),
    });

export const {
    useGetJobOfferQuery,
    useGetClientJobOfferQuery,
    useGetJobOfferFiltersQuery,
    useGetJobOfferQuestionsQuery,
    useGetJobOfferUpdateDataQuery,
    useCreateJobOfferMutation,
    useUpdateJobOfferMutation,
    useDeleteJobOfferMutation,
    useArchiveJobOfferMutation,
} = jobOfferApi;
