// extracted by mini-css-extract-plugin
export var accepted = "client-advertisement-listing-module--accepted--2460d";
export var archived = "client-advertisement-listing-module--archived--d175e";
export var backgroundAnimation = "client-advertisement-listing-module--backgroundAnimation--e4d5d";
export var createBox = "client-advertisement-listing-module--create-box--2a227";
export var grid = "client-advertisement-listing-module--grid--77dc7";
export var loader = "client-advertisement-listing-module--loader--70498";
export var loading = "client-advertisement-listing-module--loading--2ecb6";
export var sectionBox = "client-advertisement-listing-module--section-box--a4cd4";
export var table = "client-advertisement-listing-module--table--9f7ab";
export var tableGrid = "client-advertisement-listing-module--table-grid--82f14";
export var tableWrapper = "client-advertisement-listing-module--table-wrapper--fd297";