import { ILocationAddress } from './address.model';
import { ILocation } from './location.model';
import { IFileBase64 } from './file-base-64.model';
import { IMedia } from '@alterpage/gatsby-source-alterpress';

export interface IOffer {
    id: number;
    createdAt: number;
    finishedAt: number;
    name: string;
    clientId: number;
    clientName: string;
    logoUrl: string;
    cooperationForm: string;
    positionId: number;
    salaryMinDisplay: string;
    salaryMaxDisplay: string;
    location: string;
    lat: number;
    lon: number;
    pathname: string;
    street: string;
    houseNumber: string;
    apartmentNumber: string;
    zipCode: string;
    city: string;
    companyName: string;
}

export interface ISingleOffer extends IOffer {
    description: string;
    tasks: string;
    expect: string;
    relatedPaths?: Record<string, string> | null;
    hasCandidateApplication?: boolean;
}

export interface IClientOffer extends ISingleOffer {
    position: string;
    city: string;
    street: string;
    zipCode: string;
    apartmentNumber: string;
    houseNumber: string;
    translations: string[];
    status: EClientOfferStatus;
    applicationCount: number;
    projectsNames: string;
    licenses: string;
    workShift: string;
    workingTime: string;
}

export enum EClientOfferStatus {
    active = 1,
    archived,
}

export interface IJobOfferParams {
    place?: string;
    position?: number;
    radius?: number;
    lat?: number;
    lon?: number;
    category?: number;
}

export interface IJobOfferSearchLocation {
    lat: number;
    lon: number;
    radius: number;
    place: string;
}

export interface ICreateJobOfferMutation {
    companyName: string;
    salaryMin: string | number;
    salaryMax: string | number;
    address: ILocationAddress;
    location: ILocation;
    logo: IFileBase64;
    translations: Record<string, ICreateJobOfferMutationTranslation>;
    position: number;
    workingTime: string[];
    workShift: string[];
    licenses: string[];
    cooperationForm: string[];
    projectId: number | null;
}

export interface ICreateJobOfferMutationTranslation {
    name: string;
    description: string;
    tasks: string;
    expect: string;
}

export interface IJobOfferUpdateData
    extends Omit<ICreateJobOfferMutation, 'logo' | 'translations'> {
    logo: IMedia;
    translations: Record<string, IJobOfferUpdateDataTranslation>;
}

export interface IJobOfferUpdateDataTranslation
    extends Omit<ICreateJobOfferMutationTranslation, 'tasks'> {
    tasks: string;
}
