import { useMemo } from 'react';

import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';
import {
    useCreateJobOfferMutation,
    useGetClientJobOfferQuery,
    useGetJobOfferUpdateDataQuery,
    useGetJobOfferFiltersQuery,
    useGetJobOfferQuery,
    useGetJobOfferQuestionsQuery,
    useDeleteJobOfferMutation,
    useUpdateJobOfferMutation,
    useArchiveJobOfferMutation,
} from '../redux/api/job-offer';

export type TUseJobOfferQueries = 'single' | 'client' | 'questions' | 'filters' | 'update-data';

interface IUseJobOfferConfig {
    queries?: TUseJobOfferQueries[];
    jobOfferId?: number | string | undefined | null;
    clientId?: number | string | null;
    language?: string | null;
}

export const useJobOffer = ({
    queries = [],
    jobOfferId,
    clientId,
    language,
}: IUseJobOfferConfig | undefined = {}) => {
    const t = useTranslations('RTKQueryApi');

    const skipJobOfferQuery = !queries.includes('single') || !jobOfferId;
    const skipClientJobOfferQuery = !queries.includes('client') || !jobOfferId || !clientId;
    const skipQuestionsQuery = !queries.includes('questions');
    const skipFiltersQuery = !queries.includes('filters');
    const skipUpdateDataQuery = !queries.includes('update-data') || !jobOfferId;

    const getJobOfferQuery = useGetJobOfferQuery(
        { jobOfferId, language },
        { skip: skipJobOfferQuery }
    );
    const getClientJobOfferQuery = useGetClientJobOfferQuery(
        { jobOfferId, clientId, language },
        {
            skip: skipClientJobOfferQuery,
        }
    );
    const getQuestionsQuery = useGetJobOfferQuestionsQuery(
        { language },
        { skip: skipQuestionsQuery }
    );
    const getFiltersQuery = useGetJobOfferFiltersQuery(undefined, { skip: skipFiltersQuery });
    const getUpdateDataQuery = useGetJobOfferUpdateDataQuery(jobOfferId, {
        skip: skipUpdateDataQuery,
    });

    const [create, createMutation] = useCreateJobOfferMutation();
    const [deleteOffer, deleteMutation] = useDeleteJobOfferMutation();
    const [update, updateMutation] = useUpdateJobOfferMutation();
    const [archive, archiveMutation] = useArchiveJobOfferMutation();

    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getJobOfferQuery.error, t.errors);
    }, [getJobOfferQuery.error, t.errors]);
    const clientErrors = useMemo(() => {
        return getRtkQueryErrors(getClientJobOfferQuery.error, t.errors);
    }, [getClientJobOfferQuery.error, t.errors]);
    const questionsErrors = useMemo(() => {
        return getRtkQueryErrors(getQuestionsQuery.error, t.errors);
    }, [getQuestionsQuery.error, t.errors]);
    const filtersErrors = useMemo(() => {
        return getRtkQueryErrors(getFiltersQuery.error, t.errors);
    }, [getFiltersQuery.error, t.errors]);
    const updateDataErrors = useMemo(() => {
        return getRtkQueryErrors(getUpdateDataQuery.error, t.errors);
    }, [getUpdateDataQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createMutation.error, t.errors);
    }, [createMutation.error, t.errors]);
    const deleteErrors = useMemo(() => {
        return getRtkQueryErrors(deleteMutation.error, t.errors);
    }, [deleteMutation.error, t.errors]);
    const updateErrors = useMemo(() => {
        return getRtkQueryErrors(updateMutation.error, t.errors);
    }, [updateMutation.error, t.errors]);
    const archiveErrors = useMemo(() => {
        return getRtkQueryErrors(archiveMutation.error, t.errors);
    }, [archiveMutation.error, t.errors]);

    return {
        single: {
            data: getJobOfferQuery.data,
            isFetching: getJobOfferQuery.isFetching,
            isLoading: getJobOfferQuery.isLoading,
            isSuccess: getJobOfferQuery.isSuccess,
            isError: getJobOfferQuery.isError,
            errors: singleErrors,
        },
        client: {
            data: getClientJobOfferQuery.data,
            isFetching: getClientJobOfferQuery.isFetching,
            isLoading: getClientJobOfferQuery.isLoading,
            isSuccess: getClientJobOfferQuery.isSuccess,
            isError: getClientJobOfferQuery.isError,
            errors: clientErrors,
        },
        filters: {
            data: getFiltersQuery.data,
            isFetching: getFiltersQuery.isFetching,
            isLoading: getFiltersQuery.isLoading,
            isSuccess: getFiltersQuery.isSuccess,
            isError: getFiltersQuery.isError,
            errors: filtersErrors,
        },
        questions: {
            data: getQuestionsQuery.data,
            isFetching: getQuestionsQuery.isFetching,
            isLoading: getQuestionsQuery.isLoading,
            isSuccess: getQuestionsQuery.isSuccess,
            isError: getQuestionsQuery.isError,
            errors: questionsErrors,
        },
        updateData: {
            data: getUpdateDataQuery.data,
            isFetching: getUpdateDataQuery.isFetching,
            isLoading: getUpdateDataQuery.isLoading,
            isSuccess: getUpdateDataQuery.isSuccess,
            isError: getUpdateDataQuery.isError,
            errors: updateDataErrors,
        },
        create: {
            fetch: create,
            data: createMutation.data,
            isLoading: createMutation.isLoading,
            isSuccess: createMutation.isSuccess,
            isError: createMutation.isError,
            errors: createErrors,
        },
        update: {
            fetch: update,
            data: updateMutation.data,
            isLoading: updateMutation.isLoading,
            isSuccess: updateMutation.isSuccess,
            isError: updateMutation.isError,
            errors: updateErrors,
        },
        delete: {
            fetch: deleteOffer,
            data: deleteMutation.data,
            isLoading: deleteMutation.isLoading,
            isSuccess: deleteMutation.isSuccess,
            isError: deleteMutation.isError,
            errors: deleteErrors,
        },
        archive: {
            fetch: archive,
            data: archiveMutation.data,
            isLoading: archiveMutation.isLoading,
            isSuccess: archiveMutation.isSuccess,
            isError: archiveMutation.isError,
            errors: archiveErrors,
        },
    };
};
