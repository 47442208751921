import React from 'react';
import { Formik, FormikProps } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';

import {
    accepted,
    archived,
    createBox,
    grid,
    loader,
    loading,
    sectionBox,
    table,
    tableGrid,
    tableWrapper,
} from './client-advertisement-listing.module.scss';
import { ISection } from '../../models/section.model';
import { IOption } from '../../models/option.model';
import { TLocale } from '../../locale';
import { IQueryAllResult } from '../../models/query-all-result.model';
import { IPage } from '../../models/page.model';
import { EClientOfferStatus, IClientOffer } from '../../models/job-offer.model';
import useTranslations from '../../hooks/use-translations';
import { useList } from '../../hooks/use-list';
import { useClient } from '../../hooks/use-client';
import { useJobOffer } from '../../hooks/use-job-offer';
import { useModal } from '../../hooks/use-modal';
import { usePagePathname } from '../../hooks/use-page-pathname';
import { useClientPermission } from '../../hooks/use-client-permission';
import { useNoPermissionModal } from '../../hooks/use-no-permission-modal';

import Section from '../hoc/section';
import Loader from '../atoms/loader';
import Table, { ITableProps } from '../organisms/table';
import Button from '../atoms/button';
import Tooltip from '../atoms/tooltip';
import IconFactory from '../hoc/icon-factory';
import HandleFormikChange from '../hoc/handle-formik-change';
import AlertInfo from '../molecules/alert-info';

interface IClientAdvertisementListingProps {
    section: ISection;
}

interface IClientAdvertisementListingPropsQueryResult {
    allPage: IQueryAllResult<Pick<IPage, 'locale' | 'pathname' | 'type'>>;
}

const ClientAdvertisementListing: React.FC<IClientAdvertisementListingProps> = ({ section }) => {
    const { style, css, sectionId } = section;
    const { addModal } = useModal();
    const t = useTranslations('ClientAdvertisementListing');

    const client = useClient();
    const canAdvertisements = useClientPermission(['can-advertisements']);
    const { addNoPermissionRoleModal } = useNoPermissionModal();
    const jobOffer = useJobOffer({ queries: ['filters'] });
    const positions = jobOffer.filters.data?.filters.position.options;

    const { allPage } = useStaticQuery<IClientAdvertisementListingPropsQueryResult>(query);
    const detailsPage = usePagePathname(allPage, 'client-advertisement-preview');
    const advertisementCreatePage = usePagePathname(allPage, 'client-advertisement-create');

    const {
        status,
        isInitialLoading,
        items,
        paginationPaths,
        filters,
        handleChange,
        values,
        sort,
        pagination,
        handleSearch,
        initialSearchValue,
        isEmpty,
        refetchList,
    } = useList<IClientOffer>({
        endpoint: `/clients/${client.data?.clientId}/advertisements`,
        token: client.data?.token.token,
        perPage: 6,
        apiKeywords: { search: 'search' },
    });

    const isLoading = jobOffer.delete.isLoading;

    const handleOfferDelete = (offerId: string | number) => {
        addModal({
            modalKey: 'delete-confirm-modal',
            modalProps: { onConfirm: handleOfferDeleteConfirmation(offerId) },
        });
    };

    const handleOfferDeleteConfirmation = (offerId: string | number) => {
        return async () => {
            try {
                await jobOffer.delete.fetch(offerId).unwrap();
                refetchList();
            } catch {
                addModal({ modalKey: 'delete-error-modal' });
            }
        };
    };

    return (
        <Section
            className={sectionBox}
            classes={{ container: grid }}
            style={style}
            sectionId={sectionId}
            css={css}
        >
            {isInitialLoading && <Loader className={loader} />}
            {!isInitialLoading && (
                <div className={`${tableWrapper} ${isLoading ? loading : ''}`}>
                    <div className={createBox}>
                        <Button
                            {...(canAdvertisements
                                ? { as: 'link', to: advertisementCreatePage }
                                : { onClick: addNoPermissionRoleModal })}
                        >
                            {t.newAdvertisement}
                        </Button>
                        <Tooltip>{t.hint}</Tooltip>
                    </div>
                    {isEmpty && <AlertInfo>{t.empty}</AlertInfo>}
                    {!isEmpty && (
                        <Formik
                            onSubmit={() => {}}
                            initialValues={values || {}}
                            enableReinitialize={true}
                        >
                            {(formik) => (
                                <>
                                    <HandleFormikChange onChange={handleChange} />
                                    <Table
                                        className={table}
                                        tableClassName={tableGrid}
                                        headerCells={getHeaderCells(t)}
                                        rows={getOfferRows(
                                            t,
                                            items,
                                            handleOfferDelete,
                                            positions,
                                            detailsPage,
                                            advertisementCreatePage,
                                            canAdvertisements,
                                            addNoPermissionRoleModal,
                                            formik
                                        )}
                                        status={status}
                                        totalCount={pagination?.totalCount || 0}
                                        paginationPaths={paginationPaths}
                                        filters={filters}
                                        formik={formik}
                                        sort={sort}
                                        searchInputProps={{
                                            onSearch: handleSearch,
                                            placeholder: t.search,
                                            initialValue: initialSearchValue,
                                        }}
                                    />
                                </>
                            )}
                        </Formik>
                    )}
                </div>
            )}
        </Section>
    );
};

function getHeaderCells(t: TLocale['ClientAdvertisementListing']): ITableProps['headerCells'] {
    return [
        { label: t.id },
        { label: t.name },
        { label: t.position },
        { label: t.location },
        { label: t.applications, field: 'applicationCount' },
        { label: t.status.label },
        { label: t.projectName },
        { label: '' },
    ];
}

function getOfferRows(
    t: TLocale['ClientAdvertisementListing'],
    offers: IClientOffer[],
    handleAdvertisementDelete: (id: string | number) => void,
    positions: IOption[] | undefined,
    detailsPage: string,
    advertisementCreatePage: string,
    canAdvertisements: boolean,
    addNoPermissionRoleModal: () => void,
    formik: FormikProps<Record<string, string | string[]>>
): ITableProps['rows'] {
    return offers.map((offer) => {
        return [
            {
                type: 'data',
                label: t.id,
                value: offer.id,
                valueStyle: 'bold',
            },
            {
                type: 'data',
                label: t.name,
                value: offer.name,
            },
            {
                type: 'data',
                label: t.position,
                value: getPosition(offer.positionId, positions),
            },
            {
                type: 'data',
                label: t.location,
                value: offer.city,
            },
            {
                type: 'data',
                label: t.applications,
                value: offer.applicationCount,
            },
            {
                type: 'data',
                label: t.status.label,
                value: getStatusTranslation(offer.status, t) || '',
                valueClassName: offer.status === EClientOfferStatus.active ? accepted : archived,
            },
            {
                type: 'data',
                label: t.projectName,
                value: offer.projectsNames,
            },
            {
                type: 'action',
                actions: [
                    {
                        type: 'button',
                        stylePreset: 'danger',
                        size: 'medium',
                        shape: 'circle',
                        children: <IconFactory icon="trash" />,
                        onClick: canAdvertisements
                            ? () => handleAdvertisementDelete(offer.id)
                            : addNoPermissionRoleModal,
                    },
                    offer.status !== EClientOfferStatus.archived
                        ? {
                              ...(canAdvertisements
                                  ? {
                                        as: 'link',
                                        to: `${advertisementCreatePage}?id=${offer.id}&mode=edit`,
                                    }
                                  : { type: 'button', onClick: addNoPermissionRoleModal }),
                              stylePreset: 'secondary',
                              size: 'medium',
                              shape: 'circle',
                              children: <IconFactory icon="edit" />,
                          }
                        : null,
                    {
                        ...(canAdvertisements
                            ? {
                                  as: 'link',
                                  to: `${advertisementCreatePage}?id=${offer.id}&mode=copy`,
                              }
                            : { type: 'button', onClick: addNoPermissionRoleModal }),
                        stylePreset: 'secondary',
                        size: 'medium',
                        shape: 'circle',
                        children: <IconFactory icon="copy" />,
                    },
                    {
                        as: 'link',
                        stylePreset: 'secondary',
                        size: 'medium',
                        to: `${detailsPage}?id=${offer.id}${
                            formik.values.language ? '&language=' + formik.values.language : ''
                        }`,
                        children: t.details,
                    },
                ],
            },
        ];
    });
}

const getStatusTranslation = (
    status: EClientOfferStatus,
    t: TLocale['ClientAdvertisementListing']
) => {
    switch (status) {
        case EClientOfferStatus.active:
            return t.status.active;
        case EClientOfferStatus.archived:
            return t.status.archived;
    }
};

const getPosition = (id: number, positions: IOption[] | undefined) => {
    return positions?.find((position) => position.value === id)?.label || '';
};

export const query = graphql`
    query {
        allPage(
            filter: {
                type: { in: ["client-advertisement-preview", "client-advertisement-create"] }
            }
        ) {
            edges {
                node {
                    pathname
                    type
                    locale
                }
            }
        }
    }
`;

export default ClientAdvertisementListing;
